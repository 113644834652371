// extracted by mini-css-extract-plugin
export var productBox = "product-page-module--productBox--Felr4";
export var container = "product-page-module--container--3NVm0";
export var header = "product-page-module--header--2ODKB";
export var productDescription = "product-page-module--productDescription--1Y8jm";
export var productImageWrapper = "product-page-module--productImageWrapper--26E2p";
export var productImageList = "product-page-module--productImageList--18hai";
export var productImageListItem = "product-page-module--productImageListItem--3y34J";
export var scrollForMore = "product-page-module--scrollForMore--1J5Xw";
export var noImagePreview = "product-page-module--noImagePreview--3-O6I";
export var priceValue = "product-page-module--priceValue--2rzkU";
export var priceCompare = "product-page-module--priceCompare--3lurM";
export var visuallyHidden = "product-page-module--visuallyHidden--3xJUR";
export var optionsWrapper = "product-page-module--optionsWrapper--2BC3a";
export var addToCartStyle = "product-page-module--addToCartStyle--2aQ5w";
export var selectVariant = "product-page-module--selectVariant--J3Pcp";
export var labelFont = "product-page-module--labelFont--2uaoF";
export var tagList = "product-page-module--tagList--3UT9p";
export var breadcrumb = "product-page-module--breadcrumb--1Xshy";
export var metaSection = "product-page-module--metaSection--zcr_d";